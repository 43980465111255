export const environment = {
    production: true,
    VERSION: require('../../package.json').version,

    api: {
        protocol: 'https://',
        rootUrl: 'miham.si',
        port: ':8026',
        url: '/api/v0',
    },
};
