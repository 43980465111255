<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" href="#/">
            <div class="brand-logo">
                <img class="img-fluid" src="assets/img/logo_drg_bgr.svg" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets/img/logo_drg_bgr_icon.svg" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav mr-auto flex-row">
        <li class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a
                class="nav-link d-none d-md-block d-lg-block d-xl-block"
                trigger-resize=""
                (click)="toggleCollapsedSideabar()"
                *ngIf="!isCollapsedText()"
            >
                <em class="fa fa-navicon"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a
                class="nav-link sidebar-toggle d-md-none"
                (click)="settings.layout.asideToggled = !settings.layout.asideToggled; $event.stopPropagation()"
            >
                <em class="fa fa-navicon"></em>
            </a>
        </li>
        <!-- START User avatar toggle-->
        <li class="nav-item d-none d-md-block">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link" (click)="toggleUserBlock($event)">
                <em class="icon-user"></em>
            </a>
        </li>
        <li class="nav-item d-none d-md-block">
            <a class="nav-link" title="Lock screen" (click)="signOut()">
                <em class="icon-logout"></em>
            </a>
        </li>
        <!-- END User avatar toggle-->
        <!-- START lock screen-->
        <!-- <li class="nav-item d-none d-md-block">
            <a class="nav-link"
                title="Lock screen"
                [routerLink]="'/lock'">
                <em class="icon-lock"></em>
            </a>
        </li> -->
        <!-- END lock screen-->
    </ul>
    <!-- END Left navbar-->
    <!-- START Right Navbar-->

    <!-- END Right Navbar-->

    <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>
</nav>
<!-- END Top Navbar-->
