import { TsLinkUser } from './../../../shared/model/ts-link-user.model';
import { UserInfo } from './../../../shared/model/user-info.model';
import { BaseState } from '../../../shared/base/base.state';
import { UserService } from './../../../shared/services/user.service';
import { AuthService } from './../../../shared/services/auth.service';
// import { ToastrModule } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { StorageService } from '../../../shared/services/storage.service';

import { environment } from '../../../../environments/environment';
import { tap, mergeMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { BaseStateActions } from '../../../shared/base/base.actions';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    valForm: FormGroup;
    public version: string = environment.VERSION;
    constructor(
        public settings: SettingsService,
        fb: FormBuilder,
        private router: Router,
        // private toaster: ToasterService,
        // private storage: StorageService,
        private auth: AuthService,
        private userRest: UserService,
        private store: Store,
    ) {
        this.valForm = fb.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            console.log('Valid!');
            console.log(value);
        }
        this.auth
            .login(value.username, value.password)
            .pipe(
                tap((tokenData: { token: string }) =>
                    this.store.dispatch(new BaseStateActions.Common.SetToken(tokenData.token)),
                ),
                mergeMap(() =>
                    this.userRest
                        .getUserData(value.username)
                        .pipe(
                            tap((userData: TsLinkUser) =>
                                this.store.dispatch(new BaseStateActions.Common.SetUserInfo(userData)),
                            ),
                        ),
                ),
            )
            .subscribe(
                () => {
                    this.router.navigate(['/home']);
                },
                (err) => {
                    console.log(err);
                },
            );

        // if (value.username === 'admin' && value.password === 'skoki') {
        //     this.storage.setUserData(new User().deserialize({ name: 'Miha', surname: 'Boss' }));
        //     this.router.navigate(['/home']);
        // } else {
        //     this.toaster.pop('error', 'Error', 'Username or password is wrong!');
        // }
    }

    ngOnInit() {}
}
