import { TsLinkUser } from './../model/ts-link-user.model';
import { UserInfo } from './../model/user-info.model';
export namespace BaseStateActions {
    export class ClearBaseState {
        public static readonly type = '[state] ClearBaseState';
    }

    export namespace Common {
        export class SetToken {
            public static readonly type = '[Common] SetToken';
            constructor(public readonly token: string) {}
        }

        export class SetUserInfo {
            public static readonly type = '[Common] SetUserInfo';
            constructor(public readonly userInfo: TsLinkUser) {}
        }
    }
}
